<template>
    <div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>合同管理</el-breadcrumb-item>
            <el-breadcrumb-item>合同模板列表</el-breadcrumb-item>
        </el-breadcrumb> -->
        <el-card class="box-card">
            <el-row :gutter="30">
                <el-col :span="8">
                    <!--搜索-->
                    <el-input placeholder="请输入合同模板名称" v-model="QueryInfo.name" clearable @clear="GetContractTemplateList"> 
                        <el-button slot="append" icon="el-icon-search" @click="GetContractTemplateList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="GoAddContractPage">添加合同模板</el-button>
                </el-col>
            </el-row>
            <el-table :data="ContractTemplatelist" stripe>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="合同编号" prop="code"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="金额(￥)" prop="money"></el-table-column> 
                <el-table-column label="基础课时" prop="basicClassCount"></el-table-column> 
                <el-table-column label="赠送课时" prop="freeClassCount"></el-table-column> 
                <el-table-column label="起始日期" prop="startDate"></el-table-column> 
                <el-table-column label="结束日期" prop="endDate"></el-table-column> 
                <el-table-column label="是否可用" prop="isDelete">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isDelete" @change="ContractTemplateStateChanged(scope.row)"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column> 
                <el-table-column label="操作" prop="option" width="270">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="预览" placement="top" :enterable="false">
                            <el-button type="success" icon="el-icon-picture-outline" @click="ShowPreviewImageDialog(scope.row.id)">预览</el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-edit" @click="GoModifyContractPage(scope.row)">编辑</el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click="DeleteContractTemplateDialog(scope.row)">删除</el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.start"
                :page-sizes="[1,2,5,10]"
                :page-size="QueryInfo.count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="ContractTemplatetotal">
            </el-pagination>
        </el-card>



        <el-dialog title="预览合同图片" :visible.sync="PreviewImageDialogVisible" width="50%" height="50%" :close-on-click-modal="false">
             <!-- height: 70vh;
  overflow: auto; -->
            <div style="height: 60vh;overflow: auto;">
                <el-image v-for="url in ImagesUrls" :key="url" :src="url" lazy></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" :disabled="DownloadFileEnable" @click="DownloadFile">下载文件</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import {  Image,InputNumber,Switch,Checkbox,DatePicker,Cascader } from 'element-ui';
Vue.use(Image)
Vue.use(InputNumber)
Vue.use(Switch)
Vue.use(Checkbox)
Vue.use(DatePicker)
Vue.use(Cascader)
export default {
    data(){
        return{
            ContractTemplatelist:[],
            QueryInfo:{
                name:'',
                start:1,
                count:10
            },
            ContractTemplatetotal:0,
            PreviewImageDialogVisible:false,
            ImagesUrls:[],
            PreviewImageUrl:'http://localhost:5000/ContractTemplateImage/PreviewContractTemplateImage/',
            DownloadFileEnable:true,
            ContractTemplateID:0
        }
    },
    created(){
        sessionStorage.removeItem("modifycontracttemplate"); 
        this.GetContractTemplateList()
    },
    methods:{
        async GetContractTemplateList(){
            
            const {data:res} = await this.$http.get('ContractTemplate/v1/ContractTemplatesQuery',{params: this.QueryInfo})
            if(res.code !== 1){
                return this.$message.error('获取合同模板列表失败');
            }
            this.ContractTemplatelist = res.data.contractTemplates
            this.ContractTemplatetotal = res.data.total
        },
        async DeleteContractTemplateDialog(contracttemplateinfo){
            var that = this;
            const confirmResult = await that.$confirm('此操作将永远删除该合同模板, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return that.$message.info('已取消删除')
            }
            await that.$http.delete('ContractTemplate/v1/ContractTemplate',{params: {id : contracttemplateinfo.id}}).
            then(function (response) {
                if(response.data.code != 1)
                {
                    return that.$message.error('删除失败')
                }
            }).catch(function (error) {
                return that.$message.error(error)
            });
            that.$message.success('删除成功')
            that.GetContractTemplateList()
        },
        async ContractTemplateStateChanged(contracttemplateinfo){
            console.log(contracttemplateinfo)
            var ModifyContractTemplateState = {ID:contracttemplateinfo.id,State:1}
            if(contracttemplateinfo.isDelete === true){
                ModifyContractTemplateState.State = 0
            }
            await this.$http.put('ContractTemplate/v1/ContractTemplateState',ModifyContractTemplateState).
            then(function (response) {
                if(response.data.code != 1)
                {
                    return this.$message.error('设置失败')
                }
            }).catch(function (error) {
                return this.$message.error(error)
            });
            this.$message.success('设置成功')
        },
        HandleSizeChange(count){
            this.QueryInfo.count = count
            this.GetContractTemplateList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.start = start
            this.GetContractTemplateList()
        },
        GoAddContractPage(){
            this.$router.push('/AddContractT');
        },
        async ShowPreviewImageDialog(id){
            this.ImagesUrls = []
            this.DownloadFileEnable = true
            this.ContractTemplateID = 0
            const {data:res} = await this.$http.get('ContractTemplate/v1/ContractTemplateImageUrls',{params: {id:id}})
            if(res.code !== 1){
                return this.$message.error('获取预览图失败');
            }
            res.data.forEach(element => {
                this.ImagesUrls.push(this.PreviewImageUrl + element)
                this.DownloadFileEnable = false
                this.ContractTemplateID = id
            });
            this.PreviewImageDialogVisible = true
        },
        async DownloadFile(){
            window.open("http://localhost:5000/ContractTemplateFile/ContractTemplate?cid=" + this.ContractTemplateID)
        },
        GoModifyContractPage(contracttemplate){
            // console.log(contracttemplate)
            // this.$router.push({
            //     path:'/ModifyContractT',
            //     query:{
            //         ModiftInfo:contracttemplate
            //     }
            // });
            sessionStorage.setItem('modifycontracttemplate', JSON.stringify(contracttemplate))
            this.$router.push('/ModifyContractT')
            
        }

    }
}
</script>